import { Options } from "$fresh/plugins/twind.ts";
import * as colors from "twind/colors";
import { getSiteConfig } from "./config/sites.ts";
import typography from "@twind/typography";

export function createTwindConfig(siteName?: string): Options {
  // If no siteName provided, try to get it from environment or window
  let site = siteName;
  
  if (!site) {
    // Try to get from Deno environment if we're on the server
    if (typeof Deno !== 'undefined') {
      site = Deno.env.get("CURRENT_SITE");
    }
    // Try to get from window if we're in the browser
    else if (typeof window !== 'undefined') {
      site = window.SITE_NAME;
    }
  }

  if (!site) {
    // Fallback configuration if no site is specified
    return {
      selfURL: import.meta.url,
      theme: {
        extend: {
          colors: {
            ...colors,
            primary: "#000000",
            secondary: "#000000",
            accent: "#000000",
            background: "#ffffff",
            textColor: "#000000",
          },
          typography: {
            DEFAULT: {
              css: {
                color: 'var(--color-text)',
                h1: {
                  color: 'var(--color-text)',
                },
                h2: {
                  color: 'var(--color-text)',
                },
                h3: {
                  color: 'var(--color-text)',
                },
                strong: {
                  color: 'var(--color-text)',
                },
                blockquote: {
                  color: 'var(--color-text)',
                },
              },
            },
          },
        },
      },
      plugins: {
        ...typography()
      },
    };
  }

  // Get site config and create Twind options
  const siteConfig = getSiteConfig(site);  // Pass the site name explicitly
  console.log(`Creating Twind config for site: ${site} with theme:`, siteConfig.theme);
  
  return {
    selfURL: import.meta.url,
    theme: {
      extend: {
        colors: {
          ...colors,
          primary: siteConfig.theme.primary,
          secondary: siteConfig.theme.secondary,
          accent: siteConfig.theme.accent,
          background: siteConfig.theme.background,
          textColor: siteConfig.theme.text,
        },
        typography: {
          DEFAULT: {
            css: {
              color: 'var(--color-text)',
              h1: {
                color: 'var(--color-text)',
              },
              h2: {
                color: 'var(--color-text)',
              },
              h3: {
                color: 'var(--color-text)',
              },
              strong: {
                color: 'var(--color-text)',
              },
              blockquote: {
                color: 'var(--color-text)',
              },
            },
          },
        },
      },
    },
    plugins: {
      ...typography()
    },
  };
}

// Export default config for static imports
export default createTwindConfig;
